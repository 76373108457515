import React from 'react';
import getProfile from '../services/getProfile';

const Profile: React.FC<{}> = () => {
  const service = getProfile();
  const [url, setUrl] = React.useState('');

  return (
    <>
      <div className="card">
        {service.status === 'loaded' && service.payload.message}
      </div>
    </>
  );
};

export default Profile;