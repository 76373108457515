import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="container-fluid p-0 m-0">
    <div className="p-4 container-fluid">
      <h1 className="display-4">Whoops! Page not found.</h1>
      <Link to="/">Back to home</Link>
    </div>
  </div>
);

export default NotFound;