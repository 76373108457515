import React, { useState, useEffect, useContext, createContext, FC } from 'react'

interface AuthContextState {
    user: string | null;
    lastChecked: Date;
    authenticate: () => void;
    signout: () => void;
}

const defaultAuthValues: AuthContextState = {
    user: null,
    lastChecked: new Date(),
    authenticate: () => {},
    signout: () => {},
};

/** Setup the context */
const AuthContext = React.createContext<AuthContextState | null>(null)

/** Initilize the context */
export const AuthProvider: FC = ({ children }) => {
    const [user, setUser] = useState<string | null>(defaultAuthValues.user);
    const [lastChecked, setLastChecked] = useState<Date>(defaultAuthValues.lastChecked);
  
    const authenticate = () => {
        // TODO: Add in auth provider
        console.log("Authenticate")
        setUser("id goes here")
    };

    const signout = () => {
        // TODO: Add in auth provider
        console.log("Signout")
        setUser(null)
    };
  
    return (
      <AuthContext.Provider
        value={{
          user,
          lastChecked,
          authenticate,
          signout
        }}
      >
        {children}
      </AuthContext.Provider>
    );
};

// export const Auth = ({ children }: AuthProps) => (
//     <AuthContext.Provider value={defaultAuthContext}>{ children }</AuthContext.Provider>
// );

/** Use (Pull in the) the Context */
export const useAuth = () => useContext(AuthContext)