import React from 'react';
import { useAuth } from '../globals/auth'
import {
    Link
} from "react-router-dom";

const UserHeader: React.FC<{}> = () => {
  const authState = useAuth()
    let output = <Link className="btn btn-outline-light" onClick={() => { authState!.authenticate() }} to="login">Login</Link>

    if(authState!.user != null){
        output = (
            <div className="dropdown">
                <a className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    {/* <GetGravatar /> */}
                </a>
    
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                    <li>
                        <Link className="dropdown-item" to="profile">Profile</Link>
                    </li>
                    <li>
                        <Link className="dropdown-item" onClick={() => { authState!.signout() }} to="login">Logout</Link>
                    </li>
                </ul>
            </div>)
    }

  return (
    <>
      {output}
    </>
  );
};

export default UserHeader;