import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary'
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

import './App.css';

// Globals
import { AuthProvider } from './globals/auth'

// Components
import Profile from './components/profile'

// Pages
import NotFound from './pages/notFound';
import Home from './pages/home';
import Header from './pages/header';
import Footer from './pages/footer';

function ErrorFallback({error, resetErrorBoundary} : { error: any, resetErrorBoundary: any}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => { /** reset the state of your app so the error doesn't happen again */ }}>
        <AuthProvider>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/profile" element={
                <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => { /** reset the state of your app so the error doesn't happen again */ }}>
                  <Profile />
                </ErrorBoundary>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </AuthProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
