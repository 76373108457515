import React from 'react';

import UserHeader from '../components/userHeader';

import {
    BrowserRouter,
    Routes,
    Route,
    Link
  } from "react-router-dom";

class Header extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-dark bg-dark navbar-expand-sm">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">B0GY</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarMenu">
                        {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="services">Services</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="pricing">Pricing</Link>
                            </li>
                        </ul> */}
                    </div>
                    <UserHeader />
                </div>
            </nav>
        );
    }
}

Header.defaultProps = {
    AuthenticationCheck:( (props) => { return props.children } )
}

export default Header;