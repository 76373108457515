import React from 'react';

import backgroundImage1 from "../static/images/background-1.jpg";

class Home extends React.Component {
    render() {
        return (
            <div className="container-fluid p-0 m-0">
                <div className="p-4" style={{ backgroundImage: `url(${backgroundImage1})`}}>
                    <div className="p-3 m-2 rounded shadow" style={{ backgroundColor: `rgba(255, 255, 255, .50)`}}>
                        <h1 className="display-4">Work <b>SMARTER</b>, <br />Not <b>HARDER</b></h1>
                        <p className="lead">B0GYs services provided are in private alpha. And are unavailble at this time.</p>
                        <hr className="my-4" />
                        <p>B0GY provides useful plugins and API's to help bootstrap startups. Letting hackers, founders and dreamers focus on their idea not the boring stuff.</p>
                        <a className="btn btn-primary btn-lg" href="https://forms.gle/UEMkgK5n61H2i26S8" role="button">Signup, To get notified!</a>
                    </div>
                </div>
                <div className="p-4 container-fluid">
                <h1 className="display-4">Roadmap</h1>
                    <div className="row pt-4">
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Authentication</h5>
                                    <p className="card-text">Make it easy! JWT token provider with BYOK. With additional support for multi-factor and multiple signers to secure your platform.</p>
                                    <p>
                                        <span className="badge bg-secondary">Status: Alpha</span>
                                        {'\u00A0'}
                                        <span className="badge bg-dark">Beta: Q2 2022</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Profile</h5>
                                    <p className="card-text">Profile creation and storage made easy. Enable your consumers to own their data, helping you ensure compliance across the globe.</p>
                                    <p>
                                        <span className="badge bg-dark">Alpha: Q2 2022</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Audit Trail</h5>
                                    <p className="card-text">Utilising our provided libraries that not only validate auth but log what is happening to help you stay compliant and transparent with minimal effort.</p>
                                    <p>
                                        <span className="badge bg-dark">Alpha: Q3 2022</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Web3</h5>
                                    <p className="card-text">IPFS Gateway, WEB3 helpers to assist in building your smart contracts and minting your NFTs.</p>
                                    <p>
                                        <span className="badge bg-dark">Alpha: Q4 2022</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">URL Shortner</h5>
                                    <p className="card-text">Basic URI shortner with an IPFS gateway built in!</p>
                                    <p>
                                        <span className="badge bg-dark">Alpha: Q3 2022</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Authorization</h5>
                                    <p className="card-text">Ultra Fast, low latency object based AuthZ system.</p>
                                    <p>
                                        <span className="badge bg-dark">Alpha: Q1 2023</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;