import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link
  } from "react-router-dom";

class Footer extends React.Component {

    render() {
        let year = new Date().getFullYear()
        return (
            <footer className="footer bg-dark">
                <div className="container mt-auto text-center py-3 text-light">
                    <p>© {year} B0GY. All Rights Reserved</p>
                </div>
            </footer>
        );
    }
}

export default Footer;